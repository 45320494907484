import { useFrame } from '~/shared/utils';
import React from 'react';
import { Banner } from '~/shared/components/Banner';
import { Gutter } from '~/shared/components/Gutter';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { GridItem } from './components/GridItem';
import { MenuArea } from './components/MenuArea';
import { MetaArea } from './components/MetaArea';
import { Icons } from './components/MetaArea/components/Icons';
import { RichTextArea } from './components/RichTextArea';
import { UspMetaMenuItem } from './components/UspMetaMenuItem';

import {
    DesktopIconContainer,
    MobileIconContainer,
    StyledFlex,
    StyledFooter,
    StyledFooterContainer,
    StyledGrid,
} from './styled';
const N60FooterComponent = () => {
    const { data: frame } = useFrame();
    const { data } = useFrame();
    const {
        contactText,
        logoImage,
        menu,
        newsletterText,
        newsletterLink,
        paymentIcons,
        additionalIcons,
        trustpilotLink,
        rate,
    } = frame?.footer || {};

    const showMetaArea = paymentIcons?.length || trustpilotLink;
    const showGrid =
        menu ||
        contactText ||
        logoImage ||
        newsletterText ||
        newsletterLink ||
        additionalIcons?.length;

    return (
        <>
            <StyledFooter>
                {!!data.navigation?.metaMenu?.UspLinks?.length && (
                    <MobileIconContainer>
                        <Banner
                            textColor={'#' + data.header?.uspTextColor}
                            backGroundColor={'#' + data.header?.uspBackgroundColor}
                            childComponent={
                                <>
                                    {data.navigation?.metaMenu?.UspLinks?.map((section) => {
                                        return (
                                            <UspMetaMenuItem
                                                key={section.id}
                                                section={section}
                                                desktop
                                                textColor={
                                                    ('#' + data.header?.uspTextColor) as string
                                                }
                                            />
                                        );
                                    })}
                                </>
                            }
                            desktop
                        />
                    </MobileIconContainer>
                )}

                {showGrid && (
                    <>
                        <StyledFooterContainer column>
                            <MaxWidth>
                                <Gutter gutterSize="large">
                                    {menu && <MenuArea menu={menu} />}
                                    <StyledGrid>
                                        <GridItem area="richtext">
                                            {contactText || logoImage ? (
                                                <RichTextArea
                                                    text={contactText}
                                                    logoImage={logoImage}
                                                />
                                            ) : null}
                                        </GridItem>
                                        <GridItem area="icons">
                                            {!!additionalIcons?.length && (
                                                <StyledFlex>
                                                    <MobileIconContainer>
                                                        <Icons
                                                            height={24}
                                                            width={24}
                                                            icons={additionalIcons}
                                                        />
                                                    </MobileIconContainer>
                                                    <DesktopIconContainer>
                                                        <Icons
                                                            height={32}
                                                            width={32}
                                                            icons={additionalIcons}
                                                        />
                                                    </DesktopIconContainer>
                                                </StyledFlex>
                                            )}
                                        </GridItem>
                                    </StyledGrid>
                                </Gutter>
                            </MaxWidth>
                        </StyledFooterContainer>
                    </>
                )}
                {showMetaArea ? (
                    <MetaArea
                        rate={rate}
                        trustpilotLink={trustpilotLink}
                        paymentIcons={paymentIcons}
                    />
                ) : null}
            </StyledFooter>
        </>
    );
};

export const N60Footer = withErrorBoundary(N60FooterComponent);
export default N60Footer;
