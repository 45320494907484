'use client';

import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';
import { default as TrustPilotSVG } from '~/icons/trustpilot-logo.svg';
import { default as TrustPilotStarSVG } from '~/icons/trustpilot-star.svg';
import { mq } from '~/lib';

export const StyledImageContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.space[1],
    [breakpoints.sm]: {
        gap: theme.space[3],
    },
    img: {
        maxWidth: 35,
        [breakpoints.sm]: {
            maxWidth: 45,
        },
    },
}));

export const StyledSVGContainer = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
}));

export const StyledMeta = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    marginTop: theme.space[4],
    paddingTop: theme.space[3],
    paddingBottom: theme.space[3],

    [breakpoints.sm]: {
        gap: theme.space[4],
    },
}));

export const MetaContainer = styled.div({
    backgroundColor: '#000',
    minHeight: 56,
});
export const RateContainer = styled.div(({ theme }) => ({
    margin: 'auto',
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    color: theme.colors.white,
    p: {
        fontSize: theme.fontSizes['xs'],
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
}));

export const MobileImageContainer = styled.div({
    display: 'none',
    [mq(0, 'md')]: {
        display: 'flex',
    },
});

export const DesktopImageContainer = styled.div({
    display: 'none',
    [mq('md')]: {
        display: 'flex',
    },
});

export const TrustPilot = styled(TrustPilotSVG)({
    height: 16,
    width: 74,
});

export const TrustPilotStarContainer = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '& > svg:nth-of-type(n+2)': {
        // Add specific styles for the last StyledCheckLabel here
        display: 'none',
    },
    [breakpoints.sm]: {
        '& > svg:nth-of-type(n+2)': {
            // Add specific styles for the last StyledCheckLabel here
            display: 'block',
        },
    },
});

export const TrustPilotStar = styled(TrustPilotStarSVG)({
    height: 24,
    width: 24,
});
