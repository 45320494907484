'use client';

import styled from '@emotion/styled';
import { RawHtml } from '~/shared/components';
import { breakpoints } from '~/theme/breakpoints';
import Flex from '~/shared/components/Flex';

export const LogoText = styled(RawHtml)(({ theme }) => ({
    color: theme.colors.grey100,
    paddingTop: theme.space[2],
    paddingBottom: theme.space[2],
    '> p': {
        ...theme.mixins.useTextStyle('bodySmall'),
    },
}));

export const StyledFlex = styled(Flex)({
    [breakpoints.xs]: {
        alignItems: 'center',
    },
});
